import VanillaTilt from 'vanilla-tilt'

document.addEventListener('DOMContentLoaded', () => {

  const planetsWrapper = document.querySelector('.planets-wrapper');
  const planetsContainer = document.querySelector('.planets-container');
  const introCopy = document.querySelector('.intro-copy');
  const logo = document.querySelector('.logo');
  const scrollNotice = document.querySelector('.scroll-notice');
  const line = document.querySelector('.line');
  const venDiagramContainer = document.querySelector('.manifesto-ven-diagram')
  const planets = document.querySelectorAll('.planet');
  const maxMove = 40; // Maximum pixel movement
  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
  const spacing = 16
  const tiltElement = document.querySelectorAll("[data-tilt]");

  function getScrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  function calculateEndState() {
    const wrapperRect = planetsWrapper.getBoundingClientRect();
    const endHeight = wrapperRect.height;
    const endTop = 0; // End top position is 0

    return { endHeight, endTop };
  }

  function cubicBezier(t, p0, p1, p2, p3) {
    const cX = 3 * p0;
    const bX = 3 * (p2 - p0) - cX;
    const aX = 1 - cX - bX;
    const cY = 3 * p1;
    const bY = 3 * (p3 - p1) - cY;
    const aY = 1 - cY - bY;

    const x = ((aX * t + bX) * t + cX) * t;
    const y = ((aY * t + bY) * t + cY) * t;

    return y;
  }

 

  // // Mouse movement effect
  // planetsWrapper.addEventListener('mousemove', (e) => {
  //   const rect = planetsWrapper.getBoundingClientRect();
  //   const centerX = rect.width / 2;
  //   const centerY = rect.height / 2;
    
  //   const mouseX = e.clientX - rect.left - centerX;
  //   const mouseY = e.clientY - rect.top - centerY;

  //   planets.forEach((planet, index) => {
  //     const depth = Math.pow((planets.length - index) / planets.length, 2);
  //     const moveX = (-mouseX / centerX) * maxMove * depth;
  //     const moveY = (-mouseY / centerY) * maxMove * depth;

  //     planet.style.transform = `translate(calc(-50% + ${moveX}px), calc(-50% + ${moveY}px))`;
  //   });
  // });

  // // Reset positions when mouse leaves
  // planetsWrapper.addEventListener('mouseleave', () => {
  //   planets.forEach((planet) => {
  //     planet.style.transform = 'translate(-50%, -50%)';
  //   });
  // });

  function calculateProgress (element) {
    const scroll = window.pageYOffset
    const top = element.getBoundingClientRect().top + document.documentElement.scrollTop
    const height = window.innerHeight
    const elementHeight = element.offsetHeight
    const topScroll = top + scroll - (height / 2)
    let start = scroll + (top * -1)
    start = clamp(start, 0, height)
    const progress = start / (elementHeight - height)

    return progress
  }

  window.onscroll = function () {
    const lerp = (x, y, a) => x * (1 - a) + y * a
    const progress = calculateProgress(venDiagramContainer)
    console.log(progress)
    let startValue = 2
    let endValue = 60
    let offset = lerp(0, 39, progress)
    let v = lerp(startValue, endValue, progress)
    const leftCircle = document.querySelector('#left-circle')
    const rightCircle = document.querySelector('#right-circle')
    const venDiagram = document.querySelector('.manifesto-ven-diagram-container')

    const x = window.matchMedia('(min-width: 920px)')

    if (x.matches) {
      startValue = 4
      endValue = 60
      v = lerp(startValue, endValue, progress)
      offset = lerp(0, 30, progress)
      leftCircle.style.transform = `translateX(calc(${clamp(v, startValue, 50)}vw - ${clamp(offset, 0, 15)}vw))`
      rightCircle.style.transform = `translateX(calc(-${clamp(v, startValue, 50)}vw + ${clamp(offset, 0, 15)}vw))`
      if (v >= 50) {
        venDiagram.classList.add('blue')
      } else {
        venDiagram.classList.remove('blue')
      }
    } else {
      startValue = 8
      endValue = 60
      v = lerp(startValue, endValue, progress)
      offset = lerp(0, 30, progress)
      leftCircle.style.transform = `translateY(calc(${clamp(v, startValue, 50)}vh - ${clamp(offset, 0, 15)}vh))`
      rightCircle.style.transform = `translateY(calc(-${clamp(v, startValue, 50)}vh + ${clamp(offset, 0, 15)}vh))`
      if (v >= 50) {
        venDiagram.classList.add('blue')
      } else {
        venDiagram.classList.remove('blue')
      }
    }
  }

  // Initialize VanillaTilt
  VanillaTilt.init(document.querySelectorAll(".client-logo"), {
		max: 25,
    speed: 400,
    scale: 1.2,
    glare: true,
    "max-glare": 0.15
	});

  // Add this new function to handle logo clicks
  function setupLogoClicks() {
    const clientLogos = document.querySelectorAll('.client-logo');
    
    clientLogos.forEach(logo => {
      const svg = logo.querySelector('svg');
      const text = svg.getAttribute('alt') || 'This is what I did for this client and it was really amazing and they loved it so much because I am just sick.';
      let isShowingText = false;

      logo.addEventListener('click', () => {
        console.log('Logo clicked:', text);
        toggleLogoContent(logo, svg, text);
      });

      // Mouseout event on the entire logo div
      logo.addEventListener('mouseout', (event) => {
        // Check if the mouse has actually left the logo div
        if (!logo.contains(event.relatedTarget) && isShowingText) {
          toggleLogoContent(logo, svg, text);
        }
      });

      function toggleLogoContent(logo, svg, text) {
        if (isShowingText) {
          // Show SVG
          svg.style.display = 'block';
          logo.querySelector('p')?.remove();
        } else {
          // Show text
          svg.style.display = 'none';
          const p = document.createElement('p');
          p.textContent = text;
          p.style.textAlign = 'center';
          p.style.padding = '10px';
          logo.appendChild(p);
        }
        isShowingText = !isShowingText;
      }
    });
  }

  // Call the new function
  setupLogoClicks();

  const navigation = document.querySelector('.main-navigation');
  const aboutSection = document.querySelector('#about');
  const manifestoSection = document.querySelector('#manifesto');
  const sections = document.querySelectorAll('section[id]');
  const navLinks = document.querySelectorAll('.nav-links a');

  function handleNavigation() {
    const scrollPosition = window.scrollY;

    // Show/hide navigation
    if (aboutSection.getBoundingClientRect().top <= 0) {
      navigation.style.opacity = '1';
    } else {
      navigation.style.opacity = '0';
    }

    // Update active link
    sections.forEach(section => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;
      if (scrollPosition >= sectionTop - 100 && scrollPosition < sectionTop + sectionHeight - 100) {
        let activeId = section.id;
        // Treat manifesto as part of about
        if (activeId === 'manifesto') {
          activeId = 'about';
        }
        const correspondingLink = document.querySelector(`.nav-links a[href="#${activeId}"]`);
        navLinks.forEach(link => link.classList.remove('active'));
        if (correspondingLink) {
          correspondingLink.classList.add('active');
        }
      }
    });
  }

  // Initial call to set the correct state on page load
  handleNavigation();

  // Add scroll event listener
  window.addEventListener('scroll', handleNavigation);


});